import React from "react";
import Card from "react-bootstrap/Card";
import { FaCodepen, FaLeaf } from "react-icons/fa";
import { MdWork } from "react-icons/md";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="internship-info">
            Hello Everyone, I am <span className="purple">Dhruv Sharma</span>. I
            am currently interning as a{" "}
            <span className="purple">Machine Learning Engineer</span> at Safari
            Ai. I am seeking ML / Software focused internships for{" "}
            <span className="purple">Fall 2025</span>.
          </p>
          <br />
          <div className="about-sections">
            <div className="about-section">
              <FaCodepen className="about-icon" />
              <h2 className="section-title purple">Tech Enthusiast</h2>
              <ul className="about-list">
                <li className="about-activity">
                  Skilled in Python, JavaScript, and various frameworks like
                  React and PyTorch.
                </li>
                <li className="about-activity">
                  Proficient in development tools (AWS, Git, BitBucket) and
                  databases (PostgreSQL, MySQL, MS SQL).
                </li>
              </ul>
            </div>

            <div className="about-section">
              <MdWork className="about-icon" />
              <h2 className="section-title purple">Proven Industry Impact</h2>
              <ul className="about-list">
                <li className="about-activity">
                  Machine Learning Engineer Intern at Safari AI, where I
                  migrated physical server workflows to AWS SageMaker for
                  scalable model training and data sampling.
                </li>
                <li className="about-activity">
                  Optimized image embedders and ONNX-to-TensorRT pipelines to
                  accelerate inference and embedding generation, reducing
                  latency by over 20%.
                </li>
                <li className="about-activity">
                  Software Engineer at a stealth startup leveraging LLMs to
                  build a Next.js platform for designers.
                </li>
              </ul>
            </div>

            <div className="about-section">
              <FaLeaf className="about-icon" />
              <h2 className="section-title purple">
                Leadership and Innovation
              </h2>
              <ul className="about-list">
                <li className="about-activity">
                  Drove innovative projects, including M3DI AI with Flask for
                  speech-to-text transcription.
                </li>
                <li className="about-activity">
                  Created Profected, an application to connect students to
                  professionals with an AI-Powered matching system.
                </li>
              </ul>
            </div>
          </div>
          <p>
            "Intellectual learning should commence at birth, and cease only at
            death."
          </p>
          <footer className="blockquote-footer">Albert Einstein</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
